import { Field, Formik } from "formik";
import { Col, Form, Row, Spinner, Button } from "react-bootstrap";
import * as Yup from "yup";

export default function ProductForm({ action, onFormSubmit, initialValues }) {
  const schema = Yup.object().shape({
    name: Yup.string().required("Wajib diisi"),
    sku: Yup.string().nullable(),
    price: Yup.number().required("Wajib diisi"),
    qty_1: Yup.number().required("Wajib diisi"),
    qty_2: Yup.number().required("Wajib diisi"),
    qty_3: Yup.number().required("Wajib diisi"),
    metre_1: Yup.number().required("Wajib diisi"),
    metre_2: Yup.number().required("Wajib diisi"),
    metre_3: Yup.number().required("Wajib diisi"),
  });
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onFormSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, isSubmitting, touched, errors }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Nama</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="text"
                  placeholder="Nama"
                  name="name"
                  isInvalid={touched.name && !!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>SKU</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="text"
                  placeholder="SKU"
                  name="sku"
                  isInvalid={touched.sku && !!errors.sku}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sku}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Harga</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Harga"
                  name="price"
                  isInvalid={touched.price && !!errors.price}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Jumlah Gudang Toko</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Jumlah Gudang Toko"
                  name="qty_1"
                  isInvalid={touched.qty_1 && !!errors.qty_1}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.qty_1}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Meter Gudang Toko</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Meter Gudang Toko"
                  name="metre_1"
                  isInvalid={touched.metre_1 && !!errors.metre_1}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.metre_1}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Jumlah Gudang 2</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Jumlah Gudang 2"
                  name="qty_2"
                  isInvalid={touched.qty_2 && !!errors.qty_2}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.qty_2}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Meter Gudang 2</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Meter Gudang 2"
                  name="metre_2"
                  isInvalid={touched.metre_2 && !!errors.metre_2}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.metre_2}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Jumlah Gudang Anda</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Jumlah Gudang Anda"
                  name="qty_3"
                  isInvalid={touched.qty_3 && !!errors.qty_3}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.qty_3}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col md={4}>
                <Form.Label>Meter Gudang Anda</Form.Label>
              </Col>
              <Col md={8}>
                <Field
                  as={Form.Control}
                  type="number"
                  placeholder="Meter Gudang Anda"
                  name="metre_3"
                  isInvalid={touched.metre_3 && !!errors.metre_3}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.metre_3}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button type="submit">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  style={{ marginRight: "10px" }}
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}

              {action === "create" ? "Buat" : "Edit"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
