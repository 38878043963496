export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "IDR",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const numberFormatter = new Intl.NumberFormat("en-US");

export const currencyToNumber = (currency) => {
  return currency ? Number(String(currency).replace(/[^0-9.-]+/g, "")) : "";
};

export const sortCurrency = (a, b, order) => {
  const numA = currencyToNumber(a);
  const numB = currencyToNumber(b);
  if (order === "asc") {
    return numB - numA;
  }
  return numA - numB;
};

export const currencyFilter = (columnName) => ({
  onFilter: (filterVal, data) => {
    return priceFilter(filterVal, data, columnName);
  },
});

export const priceFilter = (filterVar, data, key) => {
  const price = parseInt(filterVar.number);

  if (price) {
    if (filterVar.comparator === "=") {
      return data.filter((product) => currencyToNumber(product[key]) > price);
    } else if (filterVar.comparator === "!=") {
      return data.filter((product) => currencyToNumber(product[key]) > price);
    } else if (filterVar.comparator === ">") {
      return data.filter((product) => currencyToNumber(product[key]) > price);
    } else if (filterVar.comparator === ">=") {
      return data.filter((product) => currencyToNumber(product[key]) >= price);
    } else if (filterVar.comparator === "<") {
      return data.filter((product) => currencyToNumber(product[key]) < price);
    } else if (filterVar.comparator === "<=") {
      return data.filter((product) => currencyToNumber(product[key]) <= price);
    }
  }
  return data;
};

export const footerTotal = (columnData, type) => {
  if (type === "number") {
    return numberFormatter.format(
      columnData.reduce((acc, item) => acc + currencyToNumber(item), 0)
    );
  } else if (type === "currency") {
    return currencyFormatter.format(
      columnData.reduce((acc, item) => acc + currencyToNumber(item), 0)
    );
  }
};
