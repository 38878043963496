import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import CreateProduct from "./CreateProduct";
import Inventory from "./Inventory";
import Product from "./Product";
import axios from "axios";
import Login from "./Login";
import In from "./In";
import ChangePass from "./ChangePassword";
import Out from "./Out";
import Log from "./Log";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login, logout } from "./slices/userSlice";
import ChangePassword from "./ChangePassword";

axios.defaults.baseURL =
	process.env.NODE_ENV === "development"
		? "http://127.0.0.1:8000/api"
		: "https://api.inventory-kondangjaya.com/api/";

export default function App() {
	const [isAdmin, setIsAdmin] = useState(false);
	let navigate = useNavigate();

	const dispatch = useDispatch();

	async function checkAuthenticated() {
		const auth = await axios("/auth/check", {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					dispatch(login(response.data.user));
				}
			})
			.catch((error) => {
				toast.error("Sesi anda habis. Masuk lagi untuk melanjutkan");
				navigate("/", { replace: true });
				dispatch(logout());
			});
	}

	useEffect(() => {
		checkAuthenticated();
	}, []);
	return (
		<Layout isAdmin={isAdmin}>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="product" element={<Product isAdmin={isAdmin} />} />
				<Route path="product/create" element={<CreateProduct />} />
				<Route path="inventory/in" element={<In />} />
				<Route path="inventory/out" element={<Out />} />
				<Route path="inventory" element={<Inventory />} />
				<Route path="log" element={<Log />} />
				<Route path="change-password" element={<ChangePassword />} />
			</Routes>
		</Layout>
	);
}
