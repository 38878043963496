import Header from "./Header";

export default function Layout({ children, isAdmin }) {
  return (
    <>
      <Header isAdmin={isAdmin} />
      {children}
    </>
  );
}
