import { Container, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, {
  textFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { TrashFill, PencilFill } from "react-bootstrap-icons";
import EditProductModal from "./components/EditProductModal";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { selectUser } from "./slices/userSlice";
import { useSelector } from "react-redux";
import {
  currencyFilter,
  currencyToNumber,
  currencyFormatter,
  priceFilter,
  sortCurrency,
  numberFormatter,
  footerTotal,
} from "./utils/helper";

export default function Product() {
  const columns = [
    {
      dataField: "index",
      text: "No.",
      headerStyle: { width: "5%" },
      sort: true,
      footer: "",
    },
    {
      dataField: "name",
      text: "Nama",
      sort: true,
      filter: textFilter(),
      footer: "",
    },
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
      filter: textFilter(),
      footer: "",
    },
    {
      dataField: "price",
      text: "Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("price")),
      footer: "Total",
    },
    {
      dataField: "qty_1",
      text: "Jumlah Gudang Toko",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty_1")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "metre_1",
      text: "Meter Gudang Toko",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre_1")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "qty_2",
      text: "Jumlah Gudang 2",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty_2")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "metre_2",
      text: "Meter Gudang 2",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre_2")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "qty_3",
      text: "Jumlah Gudang Anda",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty_3")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "metre_3",
      text: "Meter Gudang Anda",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre_3")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total_metre",
      text: "Total Meter",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total_metre")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total_price",
      text: "Total Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total_price")),
    },
  ];
  const adminColumns = [
    {
      dataField: "index",
      text: "No.",
      headerStyle: { width: "5%" },
      sort: true,
      footer: "",
    },
    {
      dataField: "name",
      text: "Nama",
      sort: true,
      filter: textFilter(),
      footer: "",
    },
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
      filter: textFilter(),
      footer: "",
    },
    {
      dataField: "price",
      text: "Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("price")),
      footer: "Total",
    },
    {
      dataField: "qty_1",
      text: "Jumlah Gudang Toko",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty_1")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "metre_1",
      text: "Meter Gudang Toko",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre_1")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "qty_2",
      text: "Jumlah Gudang 2",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty_2")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "metre_2",
      text: "Meter Gudang 2",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre_2")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "qty_3",
      text: "Jumlah Gudang Anda",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty_3")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "metre_3",
      text: "Meter Gudang Anda",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre_3")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total_metre",
      text: "Total Meter",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total_metre")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total_price",
      text: "Total Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total_price")),
      footer: (columnData) => footerTotal(columnData, "currency"),
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      headerStyle: { width: "10%" },
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center justify-content-around action-column">
            <Button className="mr-2" variant="dark">
              <PencilFill onClick={() => handleShow(row)} role="button" />
            </Button>
            <Button variant="danger">
              <TrashFill
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    deleteProduct(row.id);
                }}
                role="button"
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const { SearchBar } = Search;

  const deleteProduct = async (productId) => {
    await axios
      .post(
        "/products/destroy",
        {
          id: productId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        const newProducts = products.filter(
          (product) => product.id !== productId
        );
        setProducts(newProducts);
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };

  const [show, setShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const user = useSelector(selectUser);

  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    setSelectedProduct(row);
    setShow(true);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getProducts() {
      setIsLoading(true);
      const productRes = await axios("/products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const products = productRes.data.data.products.map((item, index) => {
        return {
          index: index + 1,
          id: item.id,
          sku: item.sku,
          name: item.name,
          price: currencyFormatter.format(item.price),
          qty_1: numberFormatter.format(item.warehouseproducts[0].quantity),
          qty_2: numberFormatter.format(item.warehouseproducts[1].quantity),
          qty_3: numberFormatter.format(item.warehouseproducts[2].quantity),
          metre_1: numberFormatter.format(item.warehouseproducts[0].metres),
          metre_2: numberFormatter.format(item.warehouseproducts[1].metres),
          metre_3: numberFormatter.format(item.warehouseproducts[2].metres),
          total: numberFormatter.format(
            item.warehouseproducts[0].quantity +
              item.warehouseproducts[1].quantity +
              item.warehouseproducts[2].quantity
          ),
          total_metre: numberFormatter.format(
            item.warehouseproducts[0].metres +
              item.warehouseproducts[1].metres +
              item.warehouseproducts[2].metres
          ),
          total_price: currencyFormatter.format(
            (item.warehouseproducts[0].metres +
              item.warehouseproducts[1].metres +
              item.warehouseproducts[2].metres) *
              item.price
          ),
        };
      });
      setProducts([...products]);
      setIsLoading(false);
    }

    getProducts();
  }, []);

  return (
    <Container className="mt-3">
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h4>Produk</h4>
        {user && user.isadmin ? (
          <Link to="/product/create">
            <Button variant="primary">Buat Produk</Button>
          </Link>
        ) : null}
      </div>
      {!isLoading ? (
        <ToolkitProvider
          keyField="index"
          data={products}
          columns={user && user.isadmin ? adminColumns : columns}
          bootstrap4
          search
        >
          {(props) => (
            <>
              <h6>Cari:</h6>
              <SearchBar className="mb-3" {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                pagination={paginationFactory()}
              />
            </>
          )}
        </ToolkitProvider>
      ) : (
        <Spinner animation="border" className="d-block mx-auto" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <EditProductModal
        show={show}
        allProducts={products}
        setAllProducts={setProducts}
        product={selectedProduct}
        handleClose={handleClose}
      />
    </Container>
  );
}
