import { Container } from "react-bootstrap";
import axios from "axios";
import ProductForm from "./components/ProductForm";
import { toast } from "react-toastify";

export default function CreateProduct() {
  const initialValues = {
    name: "",
    sku: "",
    price: "",
    metre_1: "",
    metre_2: "",
    metre_3: "",
    qty_1: "",
    qty_2: "",
    qty_3: "",
  };

  const onFormSubmit = async (data, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    await axios
      .post(
        "/products/create",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.api_status) {
          toast.success(response.data.message);
          resetForm();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.data.message);
      });

    setSubmitting(false);
  };
  return (
    <Container className="mt-3">
      <h4 className="mb-3">Buat Produk</h4>
      <ProductForm
        action="create"
        onFormSubmit={onFormSubmit}
        initialValues={initialValues}
      />
    </Container>
  );
}
