import { Container, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, {
  textFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import {
  currencyFilter,
  currencyToNumber,
  currencyFormatter,
  priceFilter,
  sortCurrency,
  numberFormatter,
  footerTotal,
} from "./utils/helper";
import axios from "axios";
import { selectUser } from "./slices/userSlice";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

export default function Inventory() {
  const columns = [
    {
      dataField: "index",
      text: "No.",
      headerStyle: { width: "5%" },
      sort: true,
      footer: "",
    },
    {
      dataField: "name",
      text: "Nama",
      sort: true,
      filter: textFilter(),

      footer: "",
    },
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
      filter: textFilter(),
      footer: "",
    },
    {
      dataField: "price",
      text: "Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter({
        onFilter: (filterVal, data) => {
          return priceFilter(filterVal, data, "price");
        },
      }),
      footer: "Total",
    },
    {
      dataField: "qty",
      text: "Quantity",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(),
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "metre",
      text: "Meter",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total_harga",
      text: "Total Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter({
        onFilter: (filterVal, data) => {
          return priceFilter(filterVal, data, "total_harga");
        },
      }),
      footer: "",
    },
  ];
  const adminColumns = [
    {
      dataField: "index",
      text: "No.",
      headerStyle: { width: "5%" },
      sort: true,
      footer: "",
    },
    {
      dataField: "name",
      text: "Nama",
      sort: true,
      filter: textFilter(),

      footer: "",
    },
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
      filter: textFilter(),
      footer: "",
    },
    {
      dataField: "price",
      text: "Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("price")),
      footer: "Total",
    },
    {
      dataField: "qty",
      text: "Quantity",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("qty")),
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "metre",
      text: "Meter",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("metre")),
      footer: (columnData) => footerTotal(columnData, "number"),
    },
    {
      dataField: "total_harga",
      text: "Total Harga",
      sort: true,
      sortFunc: (a, b, order) => sortCurrency(a, b, order),
      filter: numberFilter(currencyFilter("total_harga")),
      footer: (columnData) =>
        currencyFormatter.format(
          columnData.reduce((acc, item) => acc + currencyToNumber(item), 0)
        ),
    },
  ];
  const { SearchBar } = Search;

  const [warehouse, setWarehouse] = useState([]);
  const [select, setSelect] = useState("1");
  const [products, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector(selectUser);
  let first = 0;

  useEffect(() => {
    async function getWarehouse() {
      setIsLoading(true);
      const warehouseRes = await axios("/warehouse/get", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const warehouse = warehouseRes.data.data.warehouse.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });

      setWarehouse([...warehouse]);
      setIsLoading(false);
    }

    async function getProducts() {
      setIsLoading(true);
      const warehouseRes = await axios("/warehouseproducts/get", {
        params: {
          warehouse_id: select,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const products = warehouseRes.data.data.products.map((item, index) => {
        return {
          index: index + 1,
          id: item.id,
          name: item.product.name,
          sku: item.product.sku,
          metre: numberFormatter.format(item.metres),
          price: currencyFormatter.format(item.product.price),
          qty: item.quantity,
          total_harga: currencyFormatter.format(
            item.metres * item.product.price
          ),
        };
      });
      setProduct([...products]);
      setIsLoading(false);
    }

    if (first == 0) {
      getWarehouse();
      first++;
    }
    getProducts();
  }, [select]);

  return (
    <Container className="mt-3">
      <div className="align-items-center mb-5">
        <h4>Inventory Gudang</h4>
        <br />
        <h5>Pilih Gudang</h5>
        <select
          className="select-gudang form-control"
          onChange={(e) => {
            setSelect(e.target.value);
          }}
        >
          {warehouse?.map((faqs) => (
            <option key={faqs.id} value={faqs.id}>
              {faqs.name}
            </option>
          ))}
        </select>
      </div>
      {!isLoading ? (
        <ToolkitProvider
          keyField="index"
          data={products}
          columns={user && user.isadmin ? adminColumns : columns}
          bootstrap4
          search
        >
          {(props) => (
            <>
              <h6>Cari:</h6>
              <SearchBar className="mb-3" {...props.searchProps} />
              <BootstrapTable {...props.baseProps} filter={filterFactory()} />
            </>
          )}
        </ToolkitProvider>
      ) : (
        <Spinner animation="border" className="d-block mx-auto" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
}
