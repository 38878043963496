import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../slices/userSlice";
export default function Header() {
	const location = useLocation();
	let navigate = useNavigate();
	const dispatch = useDispatch();

	const user = useSelector(selectUser);

	const [isLogout, setIsLogout] = useState(false);
	const onLogout = async () => {
		setIsLogout(true);
		const logout = await axios
			.post(
				"/auth/logout",
				{
					token: localStorage.getItem("token"),
				},
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					toast.success(response.data.message);
					navigate("/", { replace: true });
					dispatch(logout());
				}
			})
			.catch((error) => {
				//toast.error(error.response.message);
			})
			.finally(() => setIsLogout(false));
	};

	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
			<Container>
				<NavLink className="navbar-brand" to="#">
					Yong
				</NavLink>
				{location.pathname !== "/" ? (
					<>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="me-auto">
								<Nav.Link as={Link} to="/product">
									Produk
								</Nav.Link>
								<Nav.Link as={Link} to="/inventory">
									Gudang
								</Nav.Link>
								<NavDropdown
									className="dropdown"
									title="Scan"
									id="collasible-nav-dropdown"
								>
									<NavDropdown.Item as={Link} to="/inventory/in">
										Barang Masuk
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/inventory/out">
										Barang Keluar
									</NavDropdown.Item>
								</NavDropdown>
							</Nav>
							<Nav>
								{user && user.isadmin ? (
									<Nav.Link as={Link} to="log">
										Log
									</Nav.Link>
								) : null}
								<Nav.Link as={Link} to="/change-password">
									Ganti Password
								</Nav.Link>
								<Nav.Link onClick={() => onLogout()}>Logout</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</>
				) : (
					" "
				)}
			</Container>
		</Navbar>
	);
}
