import { Field, Formik } from "formik";
import { Col, Form, Row, Spinner, Button } from "react-bootstrap";
import * as Yup from "yup";

export default function ScanForm({ onFormSubmit, initialValues, buttonClass }) {
	const schema = Yup.object().shape({
		sku: Yup.string().required("Wajib diisi"),
		quantity: Yup.number().required("Wajib diisi"),
		metres: Yup.number().required("Wajib diisi"),
	});
	return (
		<Formik
			validationSchema={schema}
			onSubmit={onFormSubmit}
			initialValues={initialValues}
		>
			{({ handleSubmit, isSubmitting, touched, errors }) => (
				<Form onSubmit={handleSubmit} noValidate>
					<Form.Group className="mb-3">
						<Row>
							<Col xs sm md={4}>
								<Form.Label>Nama / SKU</Form.Label>
							</Col>
							<Col xs sm md={8}>
								<Field
									as={Form.Control}
									type="text"
									placeholder="Nama / SKU"
									name="sku"
									isInvalid={touched.sku && !!errors.sku}
									autoFocus
								/>
								<Form.Control.Feedback type="invalid">
									{errors.sku}
								</Form.Control.Feedback>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group className="mb-3">
						<Row>
							<Col xs sm md={4}>
								<Form.Label>Jumlah</Form.Label>
							</Col>
							<Col xs sm md={8}>
								<Field
									as={Form.Control}
									type="number"
									placeholder="Jumlah"
									name="quantity"
									isInvalid={touched.quantity && !!errors.quantity}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.quantity}
								</Form.Control.Feedback>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group className="mb-3">
						<Row>
							<Col xs sm md={4}>
								<Form.Label>Meter</Form.Label>
							</Col>
							<Col xs sm md={8}>
								<Field
									as={Form.Control}
									type="number"
									placeholder="Meter"
									name="metres"
									isInvalid={touched.metres && !!errors.metres}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.metres}
								</Form.Control.Feedback>
							</Col>
						</Row>
					</Form.Group>
					<div className="d-flex justify-content-end">
						<Row>
							<Col xs sm md={12}>
								<Button type="submit" className={buttonClass}>
									{isSubmitting ? (
										<Spinner
											as="span"
											style={{ marginRight: "10px" }}
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									) : (
										""
									)}
									Scan
								</Button>
							</Col>
						</Row>
					</div>
				</Form>
			)}
		</Formik>
	);
}
