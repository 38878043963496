import { Container, Button, Spinner, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, {
	textFilter,
	numberFilter,
} from "react-bootstrap-table2-filter";
import ScanForm from "./components/ScanForm";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "./slices/userSlice";
import {
	currencyFormatter,
	currencyToNumber,
	priceFilter,
} from "./utils/helper";

export default function Out() {
	const columns = [
		{
			dataField: "index",
			text: "No.",
			headerStyle: { width: "10%" },
			sort: true,
			footer: "",
		},
		{
			dataField: "name",
			text: "Nama",
			sort: true,
			filter: textFilter(),

			footer: "",
		},
		{
			dataField: "sku",
			text: "SKU",
			sort: true,
			filter: textFilter(),

			footer: "",
		},
		{
			dataField: "price",
			text: "Harga",
			sort: true,
			sortFunc: (a, b, order) => {
				const numA = currencyToNumber(a);
				const numB = currencyToNumber(b);
				if (order === "asc") {
					return numB - numA;
				}
				return numA - numB;
			},
			filter: numberFilter({
				onFilter: (filterVal, data) => {
					return priceFilter(filterVal, data, "price");
				},
			}),
			footer: "Total",
		},
		{
			dataField: "qty",
			text: "Quantity",
			sort: true,
			filter: numberFilter(),
			footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
		},
		{
			dataField: "metres",
			text: "Meter",
			sort: true,
			filter: numberFilter(),
			footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
		},
		{
			dataField: "totalprice",
			text: "Total Harga",
			sort: true,
			sortFunc: (a, b, order) => {
				const numA = currencyToNumber(a);
				const numB = currencyToNumber(b);
				if (order === "asc") {
					return numB - numA;
				}
				return numA - numB;
			},
			filter: numberFilter({
				onFilter: (filterVal, data) => {
					return priceFilter(filterVal, data, "totalprice");
				},
			}),
			footer: "",
		},
	];

	const adminColumns = [
		{
			dataField: "index",
			text: "No.",
			headerStyle: { width: "10%" },
			sort: true,
			footer: "",
		},
		{
			dataField: "name",
			text: "Nama",
			sort: true,
			filter: textFilter(),

			footer: "",
		},
		{
			dataField: "sku",
			text: "SKU",
			sort: true,
			filter: textFilter(),

			footer: "",
		},
		{
			dataField: "price",
			text: "Harga",
			sort: true,
			sortFunc: (a, b, order) => {
				const numA = currencyToNumber(a);
				const numB = currencyToNumber(b);
				if (order === "asc") {
					return numB - numA;
				}
				return numA - numB;
			},
			filter: numberFilter({
				onFilter: (filterVal, data) => {
					return priceFilter(filterVal, data, "price");
				},
			}),
			footer: "Total",
		},
		{
			dataField: "qty",
			text: "Quantity",
			sort: true,
			filter: numberFilter(),
			footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
		},
		{
			dataField: "metres",
			text: "Meter",
			sort: true,
			filter: numberFilter(),
			footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
		},
		{
			dataField: "totalprice",
			text: "Total Harga",
			sort: true,
			sortFunc: (a, b, order) => {
				const numA = currencyToNumber(a);
				const numB = currencyToNumber(b);
				if (order === "asc") {
					return numB - numA;
				}
				return numA - numB;
			},
			filter: numberFilter({
				onFilter: (filterVal, data) => {
					return priceFilter(filterVal, data, "totalprice");
				},
			}),
			footer: (columnData) =>
				currencyFormatter.format(
					columnData.reduce((acc, item) => acc + currencyToNumber(item), 0)
				),
		},
	];

	const { SearchBar } = Search;
	const [warehouse, setWarehouse] = useState([]);
	const [select, setSelect] = useState("1");
	const [products, setProduct] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [product, setScannedProduct] = useState([]);
	let first = 0;

	const initialValues = {
		sku: "",
		quantity: 1,
		metres: 0,
	};
	const user = useSelector(selectUser);

	async function getProducts(first = false) {
		if (first) setIsLoading(true);
		const warehouseRes = await axios("/warehouseproducts/get", {
			params: {
				warehouse_id: select,
			},
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		});
		const products = warehouseRes.data.data.products.map((item, index) => {
			return {
				index: index + 1,
				id: item.id,
				name: item.product.name,
				sku: item.product.sku,
				qty: item.quantity,
				metres: item.metres,
				price: currencyFormatter.format(item.product.price),
				totalprice: currencyFormatter.format(item.metres * item.product.price),
			};
		});
		setProduct([...products]);
		if (first) setIsLoading(false);
	}

	const onFormSubmit = async (data, { setSubmitting, resetForm }) => {
		setSubmitting(true);
		await axios
			.post(
				"/products/scan",
				{
					...data,
					warehouse_id: select,
					action: "out",
				},
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
					},
				}
			)
			.then((response) => {
				if (response.data.api_status) {
					toast.success(response.data.message);

					const product = {
						name: response.data.data.product.name,
						sku: response.data.data.product.sku,
						qty: response.data.data.quantity,
						metres: response.data.data.metres,
						price: currencyFormatter.format(response.data.data.product.price),
						totalprice: currencyFormatter.format(
							response.data.data.metres * response.data.data.product.price
						),
					};

					setScannedProduct(product);
					getProducts();
				} else {
					toast.error(response.data.message);
					setScannedProduct(null);
				}
			});

		resetForm();
		setSubmitting(false);
	};

	useEffect(() => {
		async function getWarehouse() {
			setIsLoading(true);
			const warehouseRes = await axios("/warehouse/get", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			});
			const warehouse = warehouseRes.data.data.warehouse.map((item) => {
				return {
					id: item.id,
					name: item.name,
				};
			});

			setWarehouse([...warehouse]);
			setIsLoading(false);
		}

		if (first == 0) {
			getWarehouse();
			first++;
		}
		getProducts(true);
	}, [select]);

	return (
		<Container
			fluid
			className="pt-3 bg-danger"
			style={{ "--bs-bg-opacity": 0.1 }}
		>
			<Container>
				<div className="align-items-center mb-5">
					<h4>Barang Keluar</h4>
					<br />
					<h5>Pilih Gudang</h5>
					<select
						className="select-gudang form-control"
						onChange={(e) => {
							setSelect(e.target.value);
							setScannedProduct(null);
						}}
					>
						{warehouse?.map((wh) => (
							<option key={wh.id} value={wh.id}>
								{wh.name}
							</option>
						))}
					</select>
				</div>
				<Row>
					<Col className="mb-5" md={4}>
						{!isLoading ? (
							<Container>
								<ScanForm
									onFormSubmit={onFormSubmit}
									initialValues={initialValues}
									buttonClass="btn-danger"
								/>
								<Row style={{ marginTop: "15px" }}>
									<Col xs sm md={4}>
										Nama Produk
									</Col>
									<Col xs sm md={8}>
										: {product?.name}
									</Col>
								</Row>
								<Row>
									<Col xs sm md={4}>
										SKU
									</Col>
									<Col xs sm md={8}>
										: {product?.sku}
									</Col>
								</Row>
								<Row>
									<Col xs sm md={4}>
										Quantity
									</Col>
									<Col xs sm md={8}>
										: {product?.qty}
									</Col>
								</Row>
								<Row>
									<Col xs sm md={4}>
										Meter
									</Col>
									<Col xs sm md={8}>
										: {product?.metres}
									</Col>
								</Row>
								<Row>
									<Col xs sm md={4}>
										Harga
									</Col>
									<Col xs sm md={8}>
										: {product?.price}
									</Col>
								</Row>
								<Row>
									<Col xs sm md={4}>
										Total Harga
									</Col>
									<Col xs sm md={8}>
										: {product?.totalprice}
									</Col>
								</Row>
							</Container>
						) : (
							<Spinner
								animation="border"
								className="d-block mx-auto"
								role="status"
							>
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						)}
					</Col>
					<Col md={8}>
						{!isLoading ? (
							<ToolkitProvider
								keyField="index"
								data={products}
								columns={user && user.isadmin ? adminColumns : columns}
								bootstrap4
								search
							>
								{(props) => (
									<>
										<BootstrapTable
											{...props.baseProps}
											filter={filterFactory()}
										/>
									</>
								)}
							</ToolkitProvider>
						) : (
							<Spinner
								animation="border"
								className="d-block mx-auto"
								role="status"
							>
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						)}
					</Col>
				</Row>
			</Container>
		</Container>
	);
}
