import axios from "axios";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ProductForm from "./ProductForm";
import {
  currencyToNumber,
  currencyFormatter,
  numberFormatter,
} from "../utils/helper";

export default function EditProductModal({
  show,
  product,
  allProducts,
  setAllProducts,
  handleClose,
}) {
  const initialValues = {
    name: product.name,
    sku: product.sku,
    price: currencyToNumber(product.price),
    metre_1: currencyToNumber(product.metre_1),
    metre_2: currencyToNumber(product.metre_2),
    metre_3: currencyToNumber(product.metre_3),
    qty_1: currencyToNumber(product.qty_1),
    qty_2: currencyToNumber(product.qty_2),
    qty_3: currencyToNumber(product.qty_3),
  };

  const onFormSubmit = async (data, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await axios
      .post(
        "/products/update",
        {
          id: product.id,
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.api_status) {
          toast.success(response.data.message);
          const newProducts = [...allProducts];

          newProducts[product.index - 1] = {
            id: response.data.data.id,
            index: product.index,
            name: response.data.data.name,
            sku: response.data.data.sku,
            price: currencyFormatter.format(response.data.data.price),
            qty_1: numberFormatter.format(
              response.data.data.warehouseproducts[0].quantity
            ),
            qty_2: numberFormatter.format(
              response.data.data.warehouseproducts[1].quantity
            ),
            qty_3: numberFormatter.format(
              response.data.data.warehouseproducts[2].quantity
            ),
            metre_1: numberFormatter.format(
              response.data.data.warehouseproducts[0].metres
            ),
            metre_2: numberFormatter.format(
              response.data.data.warehouseproducts[1].metres
            ),
            metre_3: numberFormatter.format(
              response.data.data.warehouseproducts[2].metres
            ),
            total: numberFormatter.format(
              response.data.data.warehouseproducts[0].quantity +
                response.data.data.warehouseproducts[1].quantity +
                response.data.data.warehouseproducts[2].quantity
            ),
            total_metre: numberFormatter.format(
              response.data.data.warehouseproducts[0].metres +
                response.data.data.warehouseproducts[1].metres +
                response.data.data.warehouseproducts[2].metres
            ),
            total_price: currencyFormatter.format(
              (response.data.data.warehouseproducts[0].metres +
                response.data.data.warehouseproducts[1].metres +
                response.data.data.warehouseproducts[2].metres) *
                response.data.data.price
            ),
          };
          setAllProducts(newProducts);
          handleClose();
          resetForm();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.data.message);
      });

    setSubmitting(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Produk</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductForm
          action="edit"
          onFormSubmit={onFormSubmit}
          initialValues={initialValues}
        />
      </Modal.Body>
    </Modal>
  );
}
