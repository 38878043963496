import { Container, Button, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, {
	textFilter,
	numberFilter,
} from "react-bootstrap-table2-filter";

import axios from "axios";
import { useState, useEffect } from "react";

export default function Log() {
	const columns = [
		{
			dataField: "index",
			text: "No.",
			headerStyle: { width: "5%" },
			sort: true,
		},
		{
			dataField: "description",
			text: "Keterangan",
			sort: false,
			filter: textFilter(),
		},
		{
			dataField: "created_at",
			text: "Waktu",
			sort: true,
		},
	];
	const { SearchBar } = Search;

	const [logs, setLog] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const formatDate = (dateString) => {
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		};
		return new Date(dateString).toLocaleDateString(undefined, options);
	};
	let first = 0;

	async function getLogs() {
		setIsLoading(true);
		const warehouseRes = await axios("/logs/get", {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		});
		const logs = warehouseRes.data.data.logs.map((item, index) => {
			return {
				index: index + 1,
				description: item.user?.name + " " + item.description,
				created_at: formatDate(item.created_at),
			};
		});

		setLog([...logs]);
		setIsLoading(false);
	}

	const rowClasses = (row, rowIndex) => {
		let classes = null;

		if (row.description.includes("memasukkan")) {
			classes = "bg-success";
		} else if (row.description.includes("mengeluarkan")) {
			classes = "bg-danger";
		}
		return classes;
	};
	const rowStyle = {
		"--bs-bg-opacity": 0.1,
	};

	useEffect(() => {
		getLogs();
	}, []);

	return (
		<Container className="mt-3">
			<div className="align-items-center mb-5">
				<h4>Log</h4>
			</div>
			{!isLoading ? (
				<ToolkitProvider
					keyField="index"
					data={logs}
					columns={columns}
					bootstrap4
					search
				>
					{(props) => (
						<>
							<BootstrapTable
								{...props.baseProps}
								filter={filterFactory()}
								rowClasses={rowClasses}
								rowStyle={rowStyle}
							/>
						</>
					)}
				</ToolkitProvider>
			) : (
				<Spinner animation="border" className="d-block mx-auto" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			)}
		</Container>
	);
}
