import { Col, Form, Row, Container, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "./slices/userSlice";

export default function ChangePassword({}) {
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConf, setShowConf] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const router = useRouter();
  const schema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Wajib diisi")
      .min(6, "Minimal 6 karakter"),
    newPassword: Yup.string()
      .required("Wajib diisi")
      .min(6, "Minimal 6 karakter"),
    confPassword: Yup.string()
      .required("Wajib diisi")
      .min(6, "Minimal 6 karakter"),
  });
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confPassword: "",
  };
  const showPasswordHandler = () => {
    setShowConf(!showConf);
  };
  const showOldPasswordHandler = () => {
    setShowOld(!showOld);
  };
  const showNewPasswordHandler = () => {
    setShowNew(!showNew);
  };
  const onFormSubmit = async (data, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const response = await axios
      .post(
        "/user/change-password",
        {
          old_password: data.oldPassword,
          new_password: data.newPassword,
          confirm_password: data.confPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.api_status) {
          toast.success("Password berhasil diubah");
          navigate("/product", { replace: true });
        } else {
          toast.error(response.data.message);
        }
      });

    setSubmitting(false);
  };
  return (
    <section className="login">
      <Container fluid className="center-login">
        <div className="form-login">
          <h2 className="h2-login">Ubah Password</h2>
          <Formik
            validationSchema={schema}
            onSubmit={onFormSubmit}
            initialValues={initialValues}
          >
            {({ handleSubmit, isSubmitting, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="formPlaintextSpesialis">
                  <Form.Label column sm="5">
                    Password Lama:
                  </Form.Label>
                  <Col sm="7">
                    <Field
                      as={Form.Control}
                      type={showOld ? "text" : "password"}
                      name="oldPassword"
                      placeholder="Password Lama"
                      isInvalid={touched.oldPassword && !!errors.oldPassword}
                    />
                    <div className="eye" onClick={showOldPasswordHandler}>
                      {showOld ? <EyeFill /> : <EyeSlashFill />}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.oldPassword}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <br />
                <Form.Group as={Row} controlId="formPlaintextSpesialis">
                  <Form.Label column sm="5">
                    Password Baru:
                  </Form.Label>
                  <Col sm="7">
                    <Field
                      as={Form.Control}
                      type={showNew ? "text" : "password"}
                      name="newPassword"
                      placeholder="Password Baru"
                      isInvalid={touched.newPassword && !!errors.newPassword}
                    />
                    <div className="eye" onClick={showNewPasswordHandler}>
                      {showNew ? <EyeFill /> : <EyeSlashFill />}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <br />
                <Form.Group as={Row} controlId="formPlaintextSpesialis">
                  <Form.Label column sm="5">
                    Konfirmasi Password Baru:
                  </Form.Label>
                  <Col sm="7">
                    <Field
                      as={Form.Control}
                      type={showConf ? "text" : "password"}
                      name="confPassword"
                      placeholder="Konfirmasi Password Baru"
                      isInvalid={touched.confPassword && !!errors.confPassword}
                    />
                    <div className="eye" onClick={showPasswordHandler}>
                      {showConf ? <EyeFill /> : <EyeSlashFill />}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.confPassword}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <br />
                <Button
                  className="button-login"
                  width="200px"
                  disabled={isSubmitting ? true : false}
                  type="submit"
                >
                  Ganti Password
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
}
